.mobileAppBarContainer {
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    height: 88px;
    top: 0;
    position: fixed;
    width: 100%;
}

.mobileAppBarContainer .logoContainer {
    background: #2196f3;
    height: 40px;
    padding: 8px 0 0 8px;
}

.mobileAppBarContainer .logoContainer #LLT-logo {
    margin: auto 0;
}

.mobileAppBarContainer .logoContainer .userName {
    color: white;
    float: right;
    margin: 4px 8px;
}

.mobileAppBarContainer .MuiAppBar-root {
    box-shadow: unset;
    top: unset !important;
}